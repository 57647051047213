import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="footer bg-black small text-center text-white-50"><div className="container px-4 px-lg-5">Copyright &copy; Astronomical Facts! 2023</div></footer>
    </>
  )
}

export default Footer

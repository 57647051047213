import React from 'react'

const Signup = () => {
  return (
    <>
      <section className="signup-section" id="signup">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5">
                    <div className="col-md-10 col-lg-8 mx-auto text-center">
                        <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
                        <h2 className="text-white mb-5">Subscribe to receive updates!</h2>
                        {/* * * * * * * * * * * * * * * **/}
                        {/* * * SB Forms Contact Form * **/}
                        {/* * * * * * * * * * * * * * * **/}
                        {/* This form is pre-integrated with SB Forms.*/}
                        {/* To make this form functional, sign up at*/}
                        {/* https://startbootstrap.com/solution/contact-forms*/}
                        {/* to get an API token!*/}
                        <form className="form-signup" id="contactForm" data-sb-form-api-token="API_TOKEN">
                            {/* Email address input*/}
                            <div className="row input-group-newsletter">
                                <div className="col"><input className="form-control" id="emailAddress" type="email" placeholder="Enter email address..." aria-label="Enter email address..." data-sb-validations="required,email" /></div>
                                
                            </div>
                            <br/>
                            <div className="col-auto"><button className="btn btn-primary" id="submitButton" type="submit">Notify Me!</button></div>
                            <div className="invalid-feedback mt-2" data-sb-feedback="emailAddress:required">An email is required.</div>
                            <div className="invalid-feedback mt-2" data-sb-feedback="emailAddress:email">Email is not valid.</div>
                            {/* Submit success message*/}
                            {/**/}
                            {/* This is what your users will see when the form*/}
                            {/* has successfully submitted*/}
                            <div className="d-none" id="submitSuccessMessage">
                                <div className="text-center mb-3 mt-2 text-white">
                                    <div className="fw-bolder">Form submission successful!</div>
                                    To activate this form, sign up at
                                    <br />
                                    <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                </div>
                            </div>
                            {/* Submit error message*/}
                            {/**/}
                            {/* This is what your users will see when there is*/}
                            {/* an error submitting the form*/}
                            <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3 mt-2">Error sending message!</div></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Signup
